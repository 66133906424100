#card-collect-btn.segment,
#details-collect-btn.segment {
    margin: 0;
}

#list-collect-btn.segment {
    margin-top: 5px;
}

#card-collect-btn.segment .dashblock__content,
#details-collect-btn.segment .dashblock__content {
    display: flex;
    flex-direction: column;
}

#card-collect-btn.segment .dashblock__content {
    padding: 0;
    align-items: flex-end;
}

#details-collect-btn.segment .dashblock__content {
    padding: 10px 0 0 5px;
    align-items: flex-start;
}

#details-collect-btn.segment .dashblock__content > div {
    margin-left: 0;
}
