/**
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */

#fakebody {
    width: 100%;
}

.noScroll {
    overflow: hidden;
    max-height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
}

.Site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.App {
    background: #f2f2f2;
    padding-top: 50px;
    width: auto;
}

.Site-header,
.Site-footer {
    flex: none;
}

.Site-content {
    flex: 1 0 auto;
    width: 100%;
}

.Site-content .hideMsgPanel {
    display: none;
}

.Site-content.App-content {
    padding-left: 210px;
    width: auto;
    min-height: calc(100vh - 50px);
}

label {
    font-size: 16px;
    padding-bottom: 5px;
    padding-left: 3px;
    display: inline-block;
    font-weight: 500;
}

@media (max-width: 992px) {
    .Site-content.App-content {
        padding-left: 0;
    }
}

.Site-content--full {
    padding: 0;
}
.Site-content--full::after {
    content: none;
}

.ui.primary.button {
    background: #fff;
    font-weight: 200;
    color: #051d2d;
    letter-spacing: 2.6;
}

.ui.primary.button:hover {
    background: #ccc;
}

.ui.form .field > label {
    color: white;
}

.ui.statistic > .label,
.ui.statistics .statistic > .label {
    color: #ffad00;
}

.ui.statistic > .label,
.ui.statistics .statistic > .value {
    color: #fff;
}

.menu .ui.button {
    font-weight: 200;
    font-size: 14px !important;
    text-transform: uppercase;
    background-color: #fff;
}

.ui.modal p {
    color: #051d2d;
}

.ui.basic.button,
.ui.basic.buttons .button {
    background: #fff !important;
}

/* TODO: clean up f_dashboard temporary styles */
.segment.dashblock {
    background: white;
    color: #051d2d;
    padding: 0;
    margin-bottom: 40px;
}

.dashblock__head {
    color: black;
    padding: 20px;
}

.dashblock__footer {
    display: flex;
    align-items: center;
}

.dashblock__head + .dashblock__content {
    padding-top: 0;
}

.dashblock__head h2 {
    margin-top: 10px;
    font-family: "Roboto Slab";
    font-weight: lighter;
    font-size: 24px;
    color: inherit;
}

.dashblock__content {
    padding: 20px;
}

@media (max-width: 768px) {
    .dashblock__content.balance {
        padding: 20px 10px;
    }
}

.dashblock__head,
.dashblock__content,
.dashblock__content p {
    overflow: hidden;
}
