.disclaimer {
  margin: 30px auto;
  margin-top: 100px;
  text-align: left;
  width: auto;
  max-width: 1127px;
}

.disclaimer p {
  margin: auto;
  margin-bottom: 14px;
  max-width: 735px;
}

@media (max-width: 767px) {
  .disclaimer {
    margin: 75px 14px 30px;
  }
}
